import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { useCore } from "../../../hooks/useCore"
import { useApp } from "../../../hooks/useApp"
import { useSanityMentor } from "../../../hooks/useSanity"
import { useAnalytics } from "../../../hooks/useAnalytics"

export const withOnboarding = Component => ({ name = "Onboarding" }) => {
  const {
    helpers: { sanityContent },
  } = useCore()
  const { customer, setRedirectUrl, setLoaded, resetLoaded } = useApp()
  const { createMentor, loading } = useSanityMentor()
  const { trackCustomEvent } = useAnalytics()

  const onboarding = useStaticQuery(graphql`
    query SANITY_MENTOR_ONBOARDING {
      new: allSanityMentorOnboarding(filter: { handle: { current: { eq: "new" } } }) {
        edges {
          node {
            title
            handle {
              current
            }
            completion: _rawCompletion(resolveReferences: { maxDepth: 1 })
            items: _rawItems(resolveReferences: { maxDepth: 2 })
          }
        }
      }
    }
  `)

  const data = onboarding?.new?.edges?.[0]?.node
  const items = data?.items?.map(item => ({
    ...item,
    content: sanityContent(item?.content),
  }))

  const [selected, setSelected] = useState(items?.map(({ handle }) => handle?.current).reduce((a, b) => ((a[b] = []), a), {}))

  const handleSubmit = async () => {
    const interaction = selected?.interaction?.[0]
    const seeking = items
      ?.map(({ handle: id, items }) =>
        items?.map(({ handle, related }) => selected?.[id?.current]?.includes(handle?.current) && related?.map(({ _ref }) => _ref))
      )
      .flat(3)
      .filter(item => item?.length)

    resetLoaded(["customer"])
    setRedirectUrl("/account/dashboard")
    await createMentor({ customer, interaction, seeking, selected })
    trackCustomEvent("completedOnboarding", selected)
  }

  const handleSelect = ({ id, value, singleOnly }) => {
    setSelected(prevState => ({
      ...prevState,
      [id]: prevState?.[id]?.includes(value)
        ? prevState?.[id]?.filter(item => item !== value)
        : prevState?.[id]?.length && !singleOnly
        ? [...prevState?.[id], value]
        : [value],
    }))
  }

  Component.displayName = name
  return <Component items={items} selected={selected} handleSelect={handleSelect} handleSubmit={handleSubmit} loading={loading} />
}
