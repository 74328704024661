import React from "react"

import { withProgressBar } from "./withProgressBar"
import { Section, Bar } from "./ProgressBar.styled"

export const ProgressBar = withProgressBar(
  ({ min, max, current }: Props): JSX.Element => (
    <Section>
      <Bar min={min} max={max} current={current} />
    </Section>
  )
)

export interface Props {
  min: number
  max: number
  current: number
}
