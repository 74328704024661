import tw, { styled } from "twin.macro"

export { StyledStepsButton as StepsButton } from "../../styled/Steps"
export { StyledResponsive as Responsive } from "../../styled/Responsive"

export const Section = tw.section`block mb-8 md:flex md:flex-col md:justify-center md:relative w-full md:flex-1`
export const Content = tw.div`flex flex-col justify-center items-center md:w-full`
export const Item = styled.div`
  ${tw`md:w-full`}
  ${({ active }) => (active ? tw`block md:flex md:flex-col md:items-center` : tw`hidden`)}
`
