import React from "react"

import { withOnboarding } from "./withOnboarding"
import { Options } from "../../Options/Options"
import { Steps } from "../../Steps/Steps"
import { Title } from "../../Title/Title"
import { Container, StepsH1, StepsP } from "./Onboarding.styled"

export const Onboarding = withOnboarding(
  ({ items: steps, selected, handleSelect, handleSubmit, loading }): JSX.Element => (
    <>
      <Steps
        onClick={handleSubmit}
        loading={loading}
        selected={selected}
        lastButtonLabel="Finish"
        steps={steps?.map(({ handle: { current: id }, title, heading, content, items, size, final, hidePagination, singleOnly }, index) => ({
          id,
          title,
          hidePagination,
          final,
          required: !final,
          content: (
            <Container width={`xl`}>
              <Title>
                <StepsH1>{heading}</StepsH1>
                <StepsP>{content}</StepsP>
              </Title>
              {!final && items?.length > 0 && (
                <Options
                  size={size}
                  singleOnly={singleOnly}
                  items={items?.map(({ handle, title, active, filter, related }) => ({
                    id: handle?.current,
                    field: handle?.current,
                    title,
                    active,
                    filter,
                    related: related?.map(({ _ref }) => _ref),
                  }))}
                  prevSelected={() => selected?.[steps?.[index - 1 || 0]?.handle?.current]}
                  onClick={value => handleSelect({ id, value, singleOnly })}
                />
              )}
            </Container>
          ),
        }))}
      />
    </>
  )
)
