import React, { useMemo } from "react"

import { useTemplate } from "../../hooks/useTemplate"

export const withPagination = Component =>
  React.memo(({ name = "Pagination", current, active, loading, lastButtonLabel = null, lastTextOverride, nextDisabled, ...props }) => {
    const { mentor: template } = useTemplate()

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          {...props}
          template={template}
          lastTextOverride={lastTextOverride || null}
          current={current}
          active={active}
          loading={loading}
          nextDisabled={nextDisabled}
          lastButtonLabel={lastButtonLabel}
        />
      ),
      [current, active, loading, nextDisabled]
    )
  })
