import React, { useMemo, useState } from "react"

export const withSteps = Component =>
  React.memo(({ name = "Steps", activeItem = 0, steps, selected, loading, onClick, ...props }) => {
    const [active, setActive] = useState(activeItem)
    const min = 0
    const max = steps.length - 1
    const current = active + 1

    const handleClick = value => {
      if (steps?.[active]?.final) onClick()
      if (!steps?.[active]?.final) setActive(value)
    }
    Component.displayName = name
    return useMemo(
      () => (
        <Component
          {...props}
          steps={steps}
          activeItem={activeItem}
          active={active}
          min={min}
          max={max}
          current={current}
          selected={selected}
          handleClick={handleClick}
          handleActive={setActive}
          loading={loading}
          onClick={onClick}
        />
      ),
      [activeItem, active, current, selected, loading]
    )
  })
