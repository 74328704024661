import React from "react"

import { withSteps } from "./withSteps"
import { ProgressBar } from "../ProgressBar/ProgressBar"
import { Pagination } from "../Pagination/Pagination"
import { StepsButton, Responsive, Section, Content, Item } from "./Steps.styled"

export const Steps = withSteps(
  ({
    steps = [],
    onClick = () => null,
    selected,
    loading,
    lastButtonLabel,
    min,
    max,
    current,
    active,
    handleClick,
    handleActive,
  }: Props): JSX.Element => (
    <Section>
      <Responsive screen={`<md`}>
        <ProgressBar min={0} max={steps.length} current={current} />
      </Responsive>

      <Content>
        {steps.map(({ id, content, final = false, required = false }, index) => (
          <Item key={id} active={index === active}>
            {content}
            {!steps?.[active]?.hidePagination ? (
              <Responsive screen={`md>`}>
                <StepsButton
                  colour={`dark`}
                  onClick={() => handleClick(current)}
                  size={`large`}
                  theme={`primary`}
                  loading={loading}
                  disabled={required && !selected?.[id]?.length}
                >
                  {loading ? "Saving..." : active < max ? "Next" : "Finish"}
                </StepsButton>
              </Responsive>
            ) : null}
          </Item>
        ))}
      </Content>

      {!steps?.[active]?.hidePagination ? (
        <Responsive screen={`<md`}>
          <Pagination
            min={min}
            max={max}
            current={current}
            active={active}
            setActive={handleActive}
            handleSubmit={() => onClick()}
            loading={loading}
            template
            lastButtonLabel={lastButtonLabel}
          />
        </Responsive>
      ) : null}
    </Section>
  )
)

export interface Props {
  steps?: Array<{
    id: string
    title: string
    content: any
    hidePagination?: boolean
    final?: boolean
    required?: boolean
  }>
  min?: number
  max?: number
  current?: number
  active?: number
  handleClick?: (item: number) => void
  handleActive?: (item: number) => void
  activeItem?: number
  selected?: any
  loading?: boolean
  lastButtonLabel?: string | null
}
