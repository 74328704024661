import React from "react"
import { Link } from "gatsby"

import { withPagination } from "./withPagination"
import { Icon } from "../Icon/Icon"
import { Section, Count, Button, Back, Next } from "./Pagination.styled"

export const Pagination = withPagination(
  ({
    min,
    max,
    current,
    backLink,
    doneLink = null,
    active,
    setActive,
    handleSubmit = () => false,
    loading,
    lastButtonLabel = null,
    template: { requestLast, requestNext, requestStep },
    nextDisabled = false,
  }: Props): JSX.Element => (
    <Section>
      <Back
        as={backLink && !active ? Link : "a"}
        onClick={active ? () => setActive(active - 1 > min ? active - 1 : min) : null}
        to={!active && backLink ? backLink : null}
      >
        {active || backLink ? <Icon name={"chevronLeft"} size={"s"} /> : null}
      </Back>
      <Count>{requestStep?.replace(`{step}`, `${current}/${max + 1}`)}</Count>
      <Next
        as={current > max && !handleSubmit ? Link : null}
        to={current > max && !handleSubmit ? doneLink : null}
        onClick={current <= max ? () => setActive(current < max ? current : max) : active >= max ? () => handleSubmit() : null}
        loading={loading}
        disabled={nextDisabled}
      >
        {loading ? "Saving..." : active < max ? requestNext : lastButtonLabel || requestLast}
      </Next>
    </Section>
  )
)

export interface Props {
  min?: number
  max?: number
  current?: number
  backLink?: string
  doneLink?: string | null
  active?: number
  setActive?: (boolean) => void
  handleSubmit?: any
  loading?: boolean
  template?: any
  nextDisabled?: boolean
}
