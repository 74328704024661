import React from "react"
import { graphql } from "gatsby"

import { withAuthentication } from "../../hoc/withAuthentication"
import { withoutMember } from "../../hoc/withMember"
import { Onboarding as Page } from "../../components/Customer/Onboarding/Onboarding"

export const query = graphql`
  query {
    page: sanityPageAccountOnboarding {
      title
      content: _rawContent(resolveReferences: { maxDepth: 10 })
    }
  }
`

export default withAuthentication(withoutMember(({ data, ...props }) => <Page {...props} {...data} />))
